import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import ContactForm from '../components/ContactForm';

const ContactPage = () => {
    return (
        <Layout>
            <SEO title="Contact" />
            <section id="contact" className="full-width bg-primary">
                <h3 className="text-color--primary section-header" style={{ textAlign: 'center' }}>Contact Us</h3>
                <ContactForm />
            </section>
        </Layout>
    );
}

export default ContactPage;