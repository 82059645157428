/* eslint-disable no-control-regex */
import React, { useState } from 'react';
import { Row, Col, Container, Button } from 'reactstrap';
import axios from 'axios';

import { EXTERNAL_FORM_URL } from '../config';

const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
const PHONE_REGEX = /^(1?(-?\d{3})-?)?(\d{3})(-?\d{4})$/;
const SUBMIT_SUCCESS_NOTIF = 'Success!';
const SUBMIT_ERR_NOTIF = 'An error occurred. Please try again.';

const ContactForm = () => {
  const [didMessageSend, setDidMessageSend] = useState(false);
  const [notification, setNotification] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const validate = () => {
    const errors = [];

    if (!email.match(EMAIL_REGEX)) {
      errors.push('invalid email');
    }

    if (phone.length > 0 && !phone.match(PHONE_REGEX)) {
      errors.push('invalid phone');
    }

    if (!message) {
      errors.push('no message');
    }

    if (errors.length > 0) {
      setNotification(`Form contains errors: ${errors.join(', ')}.`);
      return false;
    }

    setNotification('');
    return true;
  };

  const submitForm = async () => {
    await axios.post(EXTERNAL_FORM_URL, {
      emailData: {
        email,
        phone,
        message
      }
    }).then(res => { 
        if (res.status === 200) {
          setDidMessageSend(true);
          setNotification(SUBMIT_SUCCESS_NOTIF);
        } else {
          setNotification(SUBMIT_ERR_NOTIF);
        }
      })
      .catch(err => {
        console.log(err);
        setNotification(SUBMIT_ERR_NOTIF);
      });
  } 

  return (
    <form
      className="contact-form"
      onSubmit={(e) => {
        e.preventDefault();
        if (validate()) {
          submitForm();
        }
      }}
      disabled
    >
      <fieldset disabled={didMessageSend}>
        <Container style={{ 
            maxWidth: '900px',
            marginTop: '-2rem'
        }}>
          <Row>
            <Col xs={12} md={6} style={{ marginTop: '2rem' }}>
              <label htmlFor="email" className="bold required">Email *</label>
              <input type="text" name="email" id="email" onChange={(e) => setEmail(e.target.value)} />
            </Col>
            <Col xs={12} md={6} style={{ marginTop: '2rem' }}>
              <label htmlFor="phone" className="bold optional">Phone (Optional)</label>
              <input type="text" name="phone" id="phone" onChange={(e) => setPhone(e.target.value)} />
            </Col>
          </Row>
          <Row className="form-field" style={{ marginTop: '2rem' }}>
            <Col xs={12}>
              <label htmlFor="message" className="bold required">Your message *</label>
              <textarea rows="4" name="message" id="message" onChange={(e) => setMessage(e.target.value)} />
            </Col>
          </Row>
          <Row className="form-field" style={{ marginTop: '2rem' }}>
            <Col xs={12}>
              <Button type="submit">Submit</Button>
            </Col>
          </Row>
          <Row className="form-field" style={{ marginTop: '1rem' }}>
            <Col xs={12}>
              <p className="text-color--primary bold" style={{ position: 'absolute' }}>
                {notification}
              </p>
            </Col>
          </Row>
        </Container>
      </fieldset>
    </form>
  );
};

export default ContactForm;
